import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  BioType,
  LearningsType,
} from "../types/SpeakerTypes";
import CONFIG from "../config/config";

export const NINA_MEYER_BLANKENBURG: ISpeaker = {
  slug: "nina-meyer-blankenburg",
  name: "Nina Meyer-Blankenburg",
  title: "Team Lead Psychologists @ OpenUp",
  imageUrl: CONFIG.imageUrl + "nina-meyer-blankenburg.jpg",
  color: ColorType.Mint,
  details: {
    speakerSession: {
      description: [
        "Neue Herausforderungen im Mental Well-being am Arbeitsplatz im Zusammenhang mit New Work",
      ],
      sessionType: SpeakerSessionType.Workshop,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Nina Meyer-Blankenburg ist leitende Psychologin bei OpenUp. ",
        "Mit mehreren Jahren Erfahrung in der Umsetzung innovativer Präventionsmaßnahmen, einschließlich umfangreicher Arbeit bei der Polizei Berlin, widmet sich Nina der Förderung des mentalen Wohlbefindens durch evidenzbasierte Ansätze. Sie besitzt ein Diplom in Psychologie und ist als kognitive Verhaltenstherapeutin approbiert. Ihr Fokus liegt darauf, präventive Methoden in der psychologischen Beratung zu etablieren, um die Resilienz von Menschen zu stärken und ihr mentales Wohlbefinden nachhaltig zu fördern.",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Workshop lernst du:"],
      list: [
        "Wie du klare Grenzen setzt, um akuten Stress und mentaler Überbelastung vorzubeugen",
        "Langfristige Strategien zur Vorbeugung von mentaler Überbelastung und Stress",
        "Einsicht von Expert:innen und praktische Tipps für strukturelle Veränderungen",
      ],
    },
    contact: {
      linkedInUrl: "https://www.linkedin.com/in/nina-meyer-blankenburg/",
    },
  },
};
