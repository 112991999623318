import { ISpeakerSession, ISpeaker } from "../../types/SpeakerTypes";
import SpeakerDetailsSessionBlock from "./SpeakerDetailsSessionBlock";
import SpeakerDetailsAboutBlock from "./SpeakerDetailsAboutBlock";
import { ColorType } from "../../types/ColorType";
import SpeakerLearnings from "./SpeakerLearnings";

interface SpeakerSessionProps {
  session?: ISpeakerSession;
  speaker: ISpeaker;
  color: ColorType;
  className?: string;
}

const SpeakerSession = ({
  session,
  speaker,
  color,
  className = "",
}: SpeakerSessionProps) => {
  return (
    <div className={`relative mt-8 max-w-lg flex flex-col gap-12 ${className}`}>
      {speaker?.details?.bio && (
        <SpeakerDetailsAboutBlock
          title={speaker.details.bio.title}
          description={speaker.details.bio.description}
          speakerName={speaker.name}
          color={color}
        />
      )}
      {session && (
        <SpeakerDetailsSessionBlock
          sessionType={session.sessionType}
          time={session.time}
          description={session.description}
          color={color}
        />
      )}
      {speaker.details?.learnings && (
        <SpeakerLearnings learnings={speaker.details.learnings} color={color} />
      )}
    </div>
  );
};

export default SpeakerSession;
