import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  LearningsType,
  BioType,
} from "../types/SpeakerTypes";

export const TIMO_KARSTEN: ISpeaker = {
  slug: "dr-timo-karsten",
  name: "Dr. Timo Karsten",
  title: "Fachanwalt für Arbeitsrecht @Osborne Clarke",
  imageUrl: CONFIG.imageUrl + "timo-karsten.jpg",
  color: ColorType.Black,
  details: {
    speakerSession: {
      description: [
        "Arbeitsrechtliche Gestaltungsoptionen in wirtschaftlich herausfordernden Zeiten",
      ],
      sessionType: SpeakerSessionType.Workshop,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Dr. Timo Karsten ist Fachanwalt für Arbeitsrecht und Partner der arbeitsrechtlichen Praxisgruppe in der internationalen Wirtschaftskanzlei Osborne Clarke.",
        "Er berät nationale und internationale Unternehmen im Individual- und Kollektivarbeitsrecht. Seine Schwerpunkte sind Outsourcing-Transaktionen, der Einsatz von Fremdpersonal, internationale Mobilität und grenzüberschreitende Mitarbeitereinsätze. Außerdem berät er Unternehmen im Bereich der Arbeitnehmerüberlassung wie bei alternativen Gestaltungsformen der Zusammenarbeit mit Dienstleistern und Auftragnehmern.",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Workshop lernst du:"],
      list: [
        "wie du trotz zuspitzender Wirtschaftslage wettbewerbsfähig bleibst",
        "wie vorhandene Personalressourcen effektiv und flexibel genutzt werden können",
        "mit welchen arbeitsrechtlichen Instrumenten du rechtssicher Kosten einsparen kannst",
      ],
    },
    contact: {
      linkedInUrl: "https://www.linkedin.com/in/timo-karsten-32456471",
    },
  },
};
