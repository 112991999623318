import { ColorType } from "../types/ColorType";
import { ISpeaker } from "../types/SpeakerTypes";

export const JASCHA_SOMBRUTZKI: ISpeaker = {
  slug: "jascha-sombrutzki",
  name: "Jascha Sombrutzki",
  title: "CEO @ Benefits.me",
  imageUrl: "/speakers/jascha-sombrutzki.jpg",
  color: ColorType.Mint,
  details: {
    contact: {
      linkedInUrl: "https://www.linkedin.com/in/jascha-sombrutzki/",
    },
  },
};
