import { useParams, Link } from "react-router-dom";
import SpeakerCard from "../components/content/speaker-card/SpeakerCard";
import Footer from "../components/footer/Footer";
import SpeakerSession from "../components/text/SpeakerSession";
import SPEAKERS from "../data/speakers";
import { ColorType } from "../types/ColorType";
import LogoFullCircle from "../components/svg/logos/LogoFullCircle";
import CircleRoundedBottom from "../components/circles/CircleRoundedBottom";
import CONFIG from "../config/config";
import NotFoundPage from "./NotFoundPage";

const SpeakerPage = () => {
  const { slug } = useParams();

  try {
    const decodedSpeakerId = decodeURIComponent(slug || "");
    const findSpeaker = (slug: string) => SPEAKERS.find((s) => s.slug === slug);
    const maybeSpeaker = findSpeaker(decodedSpeakerId);

    if (!maybeSpeaker) {
      return <NotFoundPage />;
    }

    const getCircleColor = (color: ColorType) =>
      color === ColorType.Mint ? ColorType.Orange : ColorType.Mint;

    const speakerColor = maybeSpeaker.color;

    return (
      <main className="relative bg-lightYellow min-h-screen w-screen pt-24 px-4 flex flex-col items-center">
        <Link to="/" className="absolute top-4 left-10">
          <LogoFullCircle className="fill-black" width={50} />
        </Link>
        <div className="max-w-screen-lg w-full mb-5 md:mb-10">
          <SpeakerCard
            speaker={maybeSpeaker}
            logoColor={speakerColor}
            placement={speakerColor === ColorType.Mint ? "left" : "right"}
          />
        </div>
        <div className="min-h-screen">
          {maybeSpeaker?.details?.speakerSession && (
            <SpeakerSession
              session={maybeSpeaker.details.speakerSession}
              speaker={maybeSpeaker}
              color={speakerColor}
              className="mb-8"
            />
          )}
        </div>
        <CircleRoundedBottom
          text="Ticket kaufen"
          target="_blank"
          link={CONFIG.ticketStoreUrl}
          color={getCircleColor(speakerColor)}
        />
        <Footer />
      </main>
    );
  } catch (error) {
    return <div>Invalid speaker URL</div>;
  }
};

export default SpeakerPage;
