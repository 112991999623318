import { ISpeaker } from "../types/SpeakerTypes";
import { JULIA_KAHLE } from "./julia-kahle";
import { FRANZ_WEGNER } from "./franz-wegner";
import { JULIA_NEUEN } from "./julia-neuen";
import { MAXIMILIAN_KOENEN } from "./maximilian-koenen";
import { ALINA_LANGENBERG } from "./alina-langenberg";
import { CHRISTIAN_GEBERT } from "./christian-gebert";
import { NINA_MEYER_BLANKENBURG } from "./nina-meyer-blankenburg";
import { TIMO_KARSTEN } from "./timo-karsten";
import { MARVIN_EBERHARD } from "./marvin-eberhard";
import { MALTE_WEISS } from "./malte-weiss";
import { JASCHA_SOMBRUTZKI } from "./jascha-sombrutzki";

const SPEAKERS: ISpeaker[] = [
  JULIA_KAHLE,
  FRANZ_WEGNER,
  JULIA_NEUEN,
  MAXIMILIAN_KOENEN,
  ALINA_LANGENBERG,
  CHRISTIAN_GEBERT,
  NINA_MEYER_BLANKENBURG,
  TIMO_KARSTEN,
  MARVIN_EBERHARD,
  MALTE_WEISS,
  JASCHA_SOMBRUTZKI,
];

export default SPEAKERS;
