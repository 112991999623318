import { ISpeaker } from "../../../types/SpeakerTypes";

interface SpeakerCardPortraitProps {
  speaker: ISpeaker;
  href?: string;
}

const SpeakerCardPortrait = ({ speaker, href }: SpeakerCardPortraitProps) => {
  if (href) {
    return (
      <a
        href={href}
        className="block hover:scale-95 transition-all duration-300"
      >
        <img
          className="rounded-full"
          src={speaker.imageUrl}
          alt={speaker.name}
          width={180}
          height={180}
        />
      </a>
    );
  }

  return (
    <div className="w-1/3 md:w-auto">
      <img
        className="rounded-full"
        src={speaker.imageUrl}
        alt={speaker.name}
        width={180}
        height={180}
      />
    </div>
  );
};

export default SpeakerCardPortrait;
