import { ColorType } from "../../types/ColorType";
import SpeakerDetailsVerticalHeadline from "./SpeakerDetailsVerticalHeadline";
import { SpeakerSessionType } from "../../types/SpeakerTypes";
import SpeakerDetailsTextBlock from "./SpeakerDetailsTextBlock";

interface SpeakerDetailsSessionBlockProps {
  time?: string;
  description: string[];
  sessionType: SpeakerSessionType;
  className?: string;
  color: ColorType;
}

const SpeakerDetailsSessionBlock = ({
  time,
  description,
  sessionType,
  className = "",
  color,
}: SpeakerDetailsSessionBlockProps) => {
  return (
    <div className={`relative flex flex-row ${className}`}>
      <SpeakerDetailsVerticalHeadline
        className={`absolute hidden md:block -left-12 ${
          color === "mint"
            ? "text-mint"
            : color === "orange"
            ? "text-orange"
            : "text-black"
        }`}
        headline={sessionType}
      />
      <div className="md:left-12">
        {time && (
          <p
            className={`${
              color === "mint"
                ? "text-mint"
                : color === "orange"
                ? "text-orange"
                : "text-black"
            } text-sm mb-2`}
          >
            {time}:
          </p>
        )}
        <h3
          className={`font-bold ${
            color === "mint"
              ? "text-mint"
              : color === "orange"
              ? "text-orange"
              : "text-black"
          } text-xl mb-2`}
        >
          {sessionType === SpeakerSessionType.Keynote ? "Vortrag" : "Workshop"}
        </h3>
        {description.map((paragraph, index) => (
          <SpeakerDetailsTextBlock
            key={index}
            description={paragraph}
            className="mb-4"
          />
        ))}
      </div>
    </div>
  );
};

export default SpeakerDetailsSessionBlock;
