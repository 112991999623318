import IconLinkedIn from "../svg/icons/IconLinkedIn";
import { IContact } from "../../types/SpeakerTypes";
import { ColorType } from "../../types/ColorType";
import SpeakerDetailsContactIcon from "./SpeakerDetailsContactIcon";
import IconTikTok from "../svg/icons/IconTikTok";
import IconInstagram from "../svg/icons/IconInstagram";

interface SpeakerDetailsContactProps {
  contact: IContact | undefined;
  className?: string;
  color: ColorType;
}

const SpeakerDetailsContact = ({
  contact,
  className = "",
  color,
}: SpeakerDetailsContactProps) => {
  return (
    <div className={`flex flex-row gap-4 ${className}`}>
      {contact?.linkedInUrl && (
        <SpeakerDetailsContactIcon href={contact.linkedInUrl} color={color}>
          <IconLinkedIn className="w-5 h-5" color={color} />
        </SpeakerDetailsContactIcon>
      )}
      {contact?.instagramUrl && (
        <SpeakerDetailsContactIcon href={contact.instagramUrl} color={color}>
          <IconInstagram className="w-5 h-5" color={color} />
        </SpeakerDetailsContactIcon>
      )}
      {contact?.tikTokUrl && (
        <SpeakerDetailsContactIcon href={contact.tikTokUrl} color={color}>
          <IconTikTok className="w-5 h-5" color={color} />
        </SpeakerDetailsContactIcon>
      )}
    </div>
  );
};

export default SpeakerDetailsContact;
