interface SpeakerDetailsVerticalHeadlineProps {
  headline: string;
  className?: string;
}

const SpeakerDetailsVerticalHeadline = ({
  headline,
  className = "",
}: SpeakerDetailsVerticalHeadlineProps) => {
  return (
    <div
      className={`absolute hidden md:block h-12 -left-12 font-bold whitespace-nowrap ${className}`}
    >
      <div className="relative -rotate-90 -translate-x-1/2">
        <span className="absolute top-0 right-1">{headline}</span>
      </div>
    </div>
  );
};

export default SpeakerDetailsVerticalHeadline;
