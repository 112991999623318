import { ColorType } from "../../types/ColorType";

interface H3Props {
  children: React.ReactNode;
  color?: ColorType;
  className?: string;
}

const H3 = ({ children, color = ColorType.Black, className = "" }: H3Props) => {
  return (
    <h3
      className={`font-bold ${
        color === ColorType.Mint
          ? "text-mint"
          : color === ColorType.Orange
          ? "text-orange"
          : "text-black"
      } text-xl mb-2 ${className}`}
    >
      {children}
    </h3>
  );
};

export default H3;
