import { Link } from "react-router-dom";

interface PrimaryButtonProps {
  children?: React.ReactNode;
  href: string;
  target?: string;
  className?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  href,
  target,
  className = "",
}) => {
  return (
    <Link
      to={href}
      target={target}
      className={`bg-black text-xl font-bold font-sans text-lightYellow px-12 py-3 rounded-none text-center cursor-pointer ${className}`}
    >
      {children}
    </Link>
  );
};

export default PrimaryButton;
