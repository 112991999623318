import { ColorType } from "../../types/ColorType";

interface SpeakerDetailsContactIconProps {
  children: React.ReactNode;
  className?: string;
  href: string;
  color: ColorType;
  target?: HTMLAnchorElement["target"];
}

const SpeakerDetailsContactIcon = ({
  children,
  className = "",
  href,
  color,
  target = "_blank",
}: SpeakerDetailsContactIconProps) => {
  return (
    <a
      href={href}
      target={target}
      className={`flex items-center justify-center min-w-9 min-h-9 p-2 border ${
        color === ColorType.Mint
          ? "border-mint"
          : color === ColorType.Orange
          ? "border-orange"
          : "border-black"
      } rounded-full ${className}`}
    >
      {children}
    </a>
  );
};

export default SpeakerDetailsContactIcon;
