import React from "react";
import SemiCircle from "../svg/SemiCircle";
import { ColorType } from "../../types/ColorType";

interface CircleRoundedBottomProps {
  link: string;
  text: string;
  target?: string;
  color?: ColorType;
}

const CircleRoundedBottom: React.FC<CircleRoundedBottomProps> = ({
  link,
  text,
  target,
  color = ColorType.Mint,
}) => {
  return (
    <div className="absolute -top-32 right-10 transition-transform duration-300 cursor-pointer hover:translate-y-2">
      <SemiCircle
        target={target}
        href={link}
        size={200}
        className={`${
          color === ColorType.Mint ? "fill-mint" : "fill-orange"
        } -rotate-90`}
      />
      <span
        className={`absolute left-1/2 bottom-7 -translate-x-1/2 text-center font-bold ${
          color === ColorType.Mint ? "text-black" : "text-lightYellow"
        } whitespace-nowrap pointer-events-none`}
      >
        {text}
      </span>
    </div>
  );
};

export default CircleRoundedBottom;
