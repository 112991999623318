import { ColorType } from "./ColorType";

export enum SpeakerSessionType {
  Keynote = "Keynote",
  Workshop = "Workshop",
}

export interface ISpeakerSession {
  time?: string;
  description: string[];
  sessionType: SpeakerSessionType;
}

export enum BioType {
  Vita = "Vita",
}

export enum LearningsType {
  Learnings = "Learnings",
}

export interface IBio {
  title: BioType;
  description: string[];
}

export interface IContact {
  linkedInUrl?: string;
  tikTokUrl?: string;
  instagramUrl?: string;
}

export interface ILearnings {
  title: string;
  description: string[];
  list: string[];
}

export interface ISpeakerDetails {
  speakerSession?: ISpeakerSession;
  bio?: IBio;
  contact?: IContact;
  learnings?: ILearnings;
}

export interface ISpeaker {
  slug: string;
  name: string;
  title: string;
  imageUrl: string;
  color: ColorType;
  details?: ISpeakerDetails;
  learnings?: ILearnings;
}
