import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import { ISpeaker } from "../types/SpeakerTypes";

export const ALINA_LANGENBERG: ISpeaker = {
  slug: "alina-langenberg",
  name: "Alina Langenberg",
  title: "Doktorandin @ WHU",
  imageUrl: CONFIG.imageUrl + "alina-langenberg.jpg",
  color: ColorType.Orange,
};
