import Hero from "../components/hero/Hero";
import DateLocationPriceSection from "../components/sections/DateLocationPriceSection";
import AboutTheEventSection from "../components/sections/AboutTheEventSection";
import SpeakersSection from "../components/sections/SpeakersSection";
import AgendaSection from "../components/sections/AgendaSection";
import PriceSection from "../components/sections/PriceSection";
import CountDownSection from "../components/sections/CountdownSection";
import ContactSection from "../components/sections/ContactSection";
import Footer from "../components/footer/Footer";

const HomePage: React.FC = () => {
  return (
    <main>
      <Hero />
      <DateLocationPriceSection />
      <AboutTheEventSection />
      <SpeakersSection />
      <AgendaSection />
      <PriceSection />
      <CountDownSection date="2025-02-13T09:30:00" />
      <ContactSection />
      <Footer />
    </main>
  );
};

export default HomePage;
