import { Link } from "react-router-dom";
import H2 from "../components/basic/H2";
import PrimaryButton from "../components/basic/PrimaryButton";
import TheSubHeadline from "../components/basic/TheSubHeadline";
import LogoFullCircle from "../components/svg/logos/LogoFullCircle";

function NotFoundPage() {
  return (
    <main className="flex flex-col items-center justify-center h-screen bg-lightYellow">
      <div className="flex flex-col items-center justify-center">
        <Link to="/" className="mb-8 cursor-pointer">
          <LogoFullCircle className="fill-black" width={150} />
        </Link>
        <H2>404 - Page Not Found</H2>
        <hr className="w-full border-mint border-2" />
        <TheSubHeadline className="mb-8">
          Die Seite, die du suchst, existiert leider nicht.
        </TheSubHeadline>
        <PrimaryButton href="/">Zur Startseite</PrimaryButton>
      </div>
    </main>
  );
}

export default NotFoundPage;
