import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  BioType,
  LearningsType,
} from "../types/SpeakerTypes";

export const MAXIMILIAN_KOENEN: ISpeaker = {
  slug: "maximilian-koenen",
  name: "Maximilian Könen",
  title: "VP - People & Organisation @ Emma - the Sleep Company",
  imageUrl: CONFIG.imageUrl + "maximilian-koenen.jpeg",
  color: ColorType.Mint,
  details: {
    speakerSession: {
      description: ["Cultural transformation after Hypergrowth"],
      sessionType: SpeakerSessionType.Keynote,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Nach fast 10 Jahren im Bereich Banking & Finance bei der Deutschen Bank und Linus Digital Finance folgte Maximilian seiner Leidenschaft und wechselte in den HR-Bereich. ",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Vortrag erhältst du folgende Einblicke:"],
      list: [
        "Wie Emma nach dem Hypergrowth ihren HR-Bereich zukunftsorientiert organisiert und transformiert",
      ],
    },
  },
};
