export enum ColorType {
  Orange = "orange",
  Mint = "mint",
  Black = "black",
}

export const colorMap = {
  [ColorType.Orange]: "#f9603b",
  [ColorType.Mint]: "#00cfb2",
  [ColorType.Black]: "#000000",
};
