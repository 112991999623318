import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  BioType,
  LearningsType,
} from "../types/SpeakerTypes";

export const JULIA_KAHLE: ISpeaker = {
  slug: "julia-kahle",
  name: "Julia Kahle",
  title: "CEO @ heynanny",
  imageUrl: CONFIG.imageUrl + "julia-kahle.jpg",
  color: ColorType.Mint,
  details: {
    speakerSession: {
      description: ["Break the Tabus"],
      sessionType: SpeakerSessionType.Keynote,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Julia Kahle ist Gründerin und Co-CEO von heynanny, einem preisgekrönten Start-up, das Unternehmen mit der gleichnamigen App hilft, Work-Life-Lösungen als Arbeitgeberbenefit anzubieten. ",
        "Als ehemalige Head of HR Marketing & Recruiting im Konzern kennt sie sich hervorragend mit Benefits und allen Themen rund ums Employer Branding aus. Zusammen mit inzwischen +200 familienfreundlichen Arbeitgebern adressiert sie das Problem der Vereinbarkeit von Beruf und Familie und bietet Betreuungslösungen für Kinder und ältere Menschen sowie im Bereich mentale Gesundheit als EAP in der DACH Region an. ",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Vortrag erhältst du folgende Einblicke:"],
      list: [
        "Wie HR Tabus rund um Hormone oder mentale Gesundheit im Arbeitsleben brechen kann",
        "Warum sich das Brechen von Tabus in mehrfacher Hinsicht für Organisationen “lohnt”",
      ],
    },
    contact: {
      linkedInUrl: "http://www.linkedin.com/in/juliakahle",
    },
  },
};
