import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  BioType,
  LearningsType,
} from "../types/SpeakerTypes";

export const MALTE_WEISS: ISpeaker = {
  slug: "malte-weiss",
  name: "Malte Weiss",
  title: "CEO @ OnApply",
  imageUrl: CONFIG.imageUrl + "malte-weiss.jpg",
  color: ColorType.Orange,
  details: {
    speakerSession: {
      description: ["In 40 Tagen zum perfekten Rekrutierungsprozess"],
      sessionType: SpeakerSessionType.Workshop,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Malte ist Gründer und Geschäftsführer von OnApply.",
        "OnApply hilft Unternehmen, den perfekten Rekrutierungsprozess zu implementieren. Malte wurde schon früh klar, dass Jobs oft nicht zu den Menschen passen. Nachdem er im Rahmen einer wissenschaftlichen Forschung Unternehmen zu ihren Rekrutierungsprozessen interviewt hatte, sah er einen großen Handlungsbedarf und so entstand die Idee für OnApply. ",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Workshop lernst du:"],
      list: [
        "Die deutliche Beschleunigung deines Bewerberauswahlprozesses – neue Mitarbeitende in unter 40 Tagen einstellen!",
        "Wie du unqualifizierte Bewerbungen um 50% reduzieren kannst",
        "Wie du keine Vermittlungsgebühren für Agenturen und Headhunter zahlen musst",
      ],
    },
    contact: {
      linkedInUrl: "https://www.linkedin.com/in/malteweiss/",
      instagramUrl: "https://instagram.com/malteweisses",
      tikTokUrl: "https://www.tiktok.com/@malteweisses",
    },
  },
};
