import { Link } from "react-router-dom";

export type LinkImageType = {
  src: string;
  name: string;
  href?: string;
};

interface LinkImageProps {
  image: LinkImageType;
}

const LinkImage = ({ image }: LinkImageProps) => {
  const content = (
    <img
      src={image.src}
      alt={image.name}
      width={400}
      height={400}
      className="h-10 md:h-14 w-auto object-contain"
      key={image.name}
    />
  );

  return image.href ? (
    <Link to={image.href} target="_blank" rel="noopener noreferrer">
      {content}
    </Link>
  ) : (
    content
  );
};

export default LinkImage;
