import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  BioType,
  LearningsType,
} from "../types/SpeakerTypes";

export const JULIA_NEUEN: ISpeaker = {
  slug: "julia-neuen",
  name: "Julia Neuen",
  title: "CEO @ peaches",
  imageUrl: CONFIG.imageUrl + "julia-neuen.jpeg",
  color: ColorType.Mint,
  details: {
    speakerSession: {
      description: [
        "Wie Unternehmen mit Female Lifecycle Management & Fertility Benefits attraktiver für Frauen werden und diese besser halten können",
      ],
      sessionType: SpeakerSessionType.Keynote,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Julia Neuen ist Serial Entrepreneur und Mutter von 4 Kindern. Unter anderem gründete sie ein marktführendes Health-Tech-Startup und gilt als inspirierende Führungspersönlichkeit, die sich leidenschaftlich für die Förderung von Frauen in der Arbeitswelt einsetzt.",
        "Mit peaches hat sie ein eigenes Consultingmodell für Unternehmen rund um „Female Lifecycle Management“ aufgebaut, um weibliche Talente zu halten, Fluktuationspunkte zu minimieren und sich als attraktiver Arbeitgeber zu positionieren. Dazu betreibt sie die größte deutschsprachige Plattform für „Family Building Benefits“, um Frauen während der wichtigsten Lebensphasen bestmöglich mit Ärzt*innen- & Expert*innen zu unterstützen.",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Vortrag erhältst du folgende Einblicke:"],
      list: [
        "Welche Relevanz die weiblichen Lebensphasen für Unternehmen haben",
        "Du lernst die wichtigsten weiblichen Fluktationspunkte kennen",
        "Lösungsansätze & Tipps, wie dein Unternehmen sich zu einem “Top Woman & Family”-Arbeitgeber entwickelt",
      ],
    },
    contact: {
      linkedInUrl:
        "https://www.linkedin.com/in/julia-neuen%F0%9F%8D%91-42911b252/",
      instagramUrl: "https://www.instagram.com/peacheswirliebenfrauenimjob/",
    },
  },
};
