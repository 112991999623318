"use client";
import React, { useEffect } from "react";

const NewsletterRegistration = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).hbspt) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).hbspt.forms.create({
          region: "na1",
          portalId: "14494208",
          formId: "54e039f7-6dc8-4aed-8d9c-b788ac25c0e9",
          target: "#hubspotForm",
        });

        // Function to change the button value
        const changeButtonValue = () => {
          const button = document.querySelector(
            ".hs-button"
          ) as HTMLInputElement;
          if (button) {
            button.value = ">";
            button.placeholder = "username@mail.com";
            return true;
          }
          return false;
        };

        // Use MutationObserver to wait for the button to be added to the DOM
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
              if (changeButtonValue()) {
                observer.disconnect(); // Stop observing once the button is found and value is changed
              }
            }
          });
        });

        const hubspotForm = document.getElementById("hubspotForm");
        if (hubspotForm) {
          observer.observe(hubspotForm, {
            childList: true,
            subtree: true,
          });
        }

        // Fallback to periodically check for the button
        const intervalId = setInterval(() => {
          if (changeButtonValue()) {
            clearInterval(intervalId); // Stop checking once the button is found and value is changed
          }
        }, 1000); // Check every second
      }
    };
    document.body.appendChild(script);
  }, []);

  return <div className="relative" id="hubspotForm"></div>;
};

export default NewsletterRegistration;
