import SPEAKERS from "../data/speakers";
import { IContact, ISpeaker, ISpeakerSession } from "../types/SpeakerTypes";

interface UseSpeakerData {
  speakers: ISpeaker[];
  hasSpeakerSession: (slug: string) => boolean;
  getSpeakerSession: (slug: string) => ISpeakerSession | undefined;
  getSpeakerContact: (slug: string) => IContact | undefined;
}

const useSpeakerData = (): UseSpeakerData => {
  const findSpeaker = (slug: string): ISpeaker | undefined =>
    SPEAKERS.find((s) => s.slug === slug);

  const hasSpeakerSession = (slug: string) => {
    const speaker = findSpeaker(slug);
    if (!speaker) {
      return false;
    }
    const speakerSession = speaker.details?.speakerSession;

    return speakerSession ? true : false;
  };

  const getSpeakerSession = (slug: string) => {
    const speaker = findSpeaker(slug);
    return speaker?.details?.speakerSession;
  };

  const getSpeakerContact = (slug: string): IContact | undefined => {
    const speaker = findSpeaker(slug);
    return speaker?.details?.contact;
  };

  return {
    speakers: SPEAKERS,
    hasSpeakerSession,
    getSpeakerSession,
    getSpeakerContact,
  };
};

export default useSpeakerData;
