import { ILearnings } from "../../types/SpeakerTypes";
import H3 from "../basic/H3";
import { colorMap, ColorType } from "../../types/ColorType";
interface SpeakerLearningsProps {
  learnings: ILearnings;
  className?: string;
  color: ColorType;
}

const SpeakerLearnings = ({
  learnings,
  className = "",
  color,
}: SpeakerLearningsProps) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <H3 className="mb-2" color={color}>
        {learnings.title}
      </H3>
      <div className="mb-4">
        {learnings.description.map((description, index) => (
          <p key={index}>{description}</p>
        ))}
      </div>
      <ul className="mb-4 ml-4 list-disc" style={{ color: colorMap[color] }}>
        {learnings.list.map((learning) => (
          <li className="mb-2 [&>*]:text-black" key={learning}>
            <span>{learning}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SpeakerLearnings;
