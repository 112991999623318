import SpeakerDetailsVerticalHeadline from "./SpeakerDetailsVerticalHeadline";
import { ColorType } from "../../types/ColorType";
import SpeakerDetailsTextBlock from "./SpeakerDetailsTextBlock";
import H3 from "../basic/H3";

interface SpeakerDetailsAboutBlockProps {
  title: string;
  description: string[];
  speakerName: string;
  className?: string;
  color: ColorType;
}

const SpeakerDetailsAboutBlock = ({
  title,
  description,
  speakerName,
  className = "",
  color,
}: SpeakerDetailsAboutBlockProps) => {
  return (
    <div className={`relative flex flex-row ${className}`}>
      <SpeakerDetailsVerticalHeadline
        className={`absolute hidden md:block -left-12 ${
          color === ColorType.Mint
            ? "text-mint"
            : color === ColorType.Orange
            ? "text-orange"
            : "text-black"
        }`}
        headline={`Über ${speakerName}`}
      />
      <div className="md:left-12">
        <H3 color={color}>{title}</H3>
        {description.map((paragraph, index) => (
          <SpeakerDetailsTextBlock key={index} description={paragraph} />
        ))}
      </div>
    </div>
  );
};

export default SpeakerDetailsAboutBlock;
