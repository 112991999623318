import { ColorType } from "../types/ColorType";
import {
  BioType,
  ISpeaker,
  LearningsType,
  SpeakerSessionType,
} from "../types/SpeakerTypes";

export const JASCHA_SOMBRUTZKI: ISpeaker = {
  slug: "jascha-sombrutzki",
  name: "Jascha Sombrutzki",
  title: "CEO @ Benefits.me",
  imageUrl: "/speakers/jascha-sombrutzki.jpg",
  color: ColorType.Mint,
  details: {
    speakerSession: {
      description: [
        "New Work, New Benefits – Was attraktive Arbeitgeber:innen 2025 ausmacht.",
      ],
      sessionType: SpeakerSessionType.Keynote,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Jascha Sombrutzki kam im Rahmen seines Studiums an der Universität Koblenz durch ein Praktikum bei Airbnb erstmals mit der Startup-Welt in Kontakt. Zeitgleich mit dem Abschluss des Studiums im Jahr 2013 stieg er als Mitgründer bei der Ticketsprinter GmbH ein, die im April 2023 zur Benefits.me GmbH umfirmiert hat. Jascha beschäftigt sich seit mittlerweile 10 Jahren intensiv mit den Themen New Work, Unternehmenskultur und – natürlich – Benefits.",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Vortrag lernst du:"],
      list: [
        "Was die “Mindestanforderungen” an attraktive Arbeitgeber:innen sind",
        "Wie sie darüber hinaus aus der Masse herausstechen können",
        "Kostengünstige und direkt umsetzbare Maßnahmen zur Steigerung der Arbeitgeber:innenattraktivität",
      ],
    },
    contact: {
      linkedInUrl: "https://www.linkedin.com/in/jascha-sombrutzki/",
    },
  },
};
