"use client";

import { useState, useEffect, useCallback } from "react";
import SectionContainer from "../container/SectionContainer";
import Countdown, { CountDownType } from "../content/CountDown";
import TheSubHeadline from "../basic/TheSubHeadline";
import H2 from "../basic/H2";

interface CountdownProps {
  date: string;
}

const CountDownSection: React.FC<CountdownProps> = ({ date }) => {
  const [countdown, setCountdown] = useState<CountDownType>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = useCallback(() => {
    const targetDate = new Date(date);
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    if (difference > 0) {
      const timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      setCountdown(timeLeft);
    } else {
      setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  }, [date]);

  useEffect(() => {
    const interval = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(interval);
  }, [date, calculateTimeLeft]);

  return (
    <SectionContainer
      id="section-countdown"
      className="flex justify-center py-14 bg-mint"
    >
      <div className="flex flex-col md:flex-row container max-w-screen-lg">
        <div className="flex flex-col mr-10 w-full max-w-screen-lg">
          <TheSubHeadline className="text-yellow">Countdown bis</TheSubHeadline>
          <H2 className="text-yellow">Full Circle 2025</H2>
        </div>
        <Countdown {...countdown} />
      </div>
    </SectionContainer>
  );
};

export default CountDownSection;
