import { ColorType } from "../types/ColorType";
import {
  BioType,
  ISpeaker,
  LearningsType,
  SpeakerSessionType,
} from "../types/SpeakerTypes";

export const FRANZ_WEGNER: ISpeaker = {
  slug: "franz-wegner",
  name: "Franz Wegner",
  title: "Geschäftsführer @Content Minds GmbH",
  imageUrl: "/speakers/franz-wegner.jpeg",
  color: ColorType.Orange,
  details: {
    bio: {
      title: BioType.Vita,
      description: [
        "2022 machte Frank Wegner sein Hobby zum Beruf: Er führte die erlernten Skills aus seiner YouTube-Zeit als Content Creator mit seinem Beruf zusammen und gründete eine Full-Service-Content-Agentur in Berlin.",
      ],
    },
    speakerSession: {
      description: [
        "TikTok Power Recruiting: 5 Ideen für mehr Bewerbungen durch 9:16",
      ],
      sessionType: SpeakerSessionType.Workshop,
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Workshop lernst du:"],
      list: [
        "wie du mit TikTok und anderen 9:16-Plattformen neue Bewerbungen generierst",
        "Praxistipps aus eigener Erfahrung zu gutem Content und dem richtigen Equipment",
        "Genaue Schritt-für-Schritt-Anleitungen",
      ],
    },
  },
};
