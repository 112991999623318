import { ISpeaker } from "../../../types/SpeakerTypes";

interface SpeakerCardNameTitleProps {
  speaker: ISpeaker;
  href?: string;
}

const SpeakerCardNameTitle = ({ speaker, href }: SpeakerCardNameTitleProps) => {
  if (href) {
    return (
      <a
        href={href}
        className="block cursor-pointer transition-all duration-300"
      >
        <p className="font-sans text-lg md:text-2xl md:w-auto font-bold">
          {speaker.name}
        </p>
        <p className="font-normal text-normal md:text-xl mb-4">
          {speaker.title}
        </p>
      </a>
    );
  }

  return (
    <>
      <p className="font-sans text-lg md:text-2xl md:w-auto font-bold">
        {speaker.name}
      </p>
      <p className="font-normal text-normal md:text-xl mb-4">{speaker.title}</p>
    </>
  );
};

export default SpeakerCardNameTitle;
