import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  LearningsType,
  BioType,
} from "../types/SpeakerTypes";

export const MARVIN_EBERHARD: ISpeaker = {
  slug: "marvin-eberhard",
  name: "Marvin Eberhard",
  title: "Regionalleitung @ BBGM-Regionalgruppe Hessen",
  imageUrl: CONFIG.imageUrl + "marvin-eberhard.png",
  color: ColorType.Mint,
  details: {
    speakerSession: {
      description: [
        "Ist das noch GESUND für das Unternehmen oder kann das weg?",
      ],
      sessionType: SpeakerSessionType.Workshop,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Mit über fünf Jahren Erfahrung im Bereich der digitalen Gesundheit hat Marvin als Unternehmensberater und Produktmanager für digitale betriebliche Gesundheitsförderung (BGF) fundierte Expertise aufgebaut. Seit dem Master-Abschluss widmet er sich der Digitalisierung im Gesundheitswesen und treibt innovative Lösungen in der Prävention voran.",
        "Mit den modernen Check-up-Lösungen von Global Health Management ermöglicht Marvin ortsunabhängige Screenings für Mitarbeitende, darunter Analysen zu Stress, Schlaf, Blutgesundheit und Herz-Kreislauf-Funktion.",
        "Als Regionalleiter beim Bundesverband Betriebliches Gesundheitsmanagement (BBGM) bringt Marvin seine Expertise aktiv in die Weiterentwicklung und Förderung moderner Ansätze der Gesundheitsförderung ein.",
        "Sein Ziel ist es, die betriebliche Gesundheitsförderung und Prävention durch datenbasierte Ansätze individueller und effektiver zu gestalten – und deren Nutzen als messbare Investition in Mitarbeitende sichtbar zu machen.",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Workshop lernst du:"],
      list: [
        "Was ein gemeinsames Gesundheitsverständnis im Unternehmen ist",
        "Wie du dieses förderst und warum es so wichtig für den Erfolg eines BGM ist",
        "Wie die gängigen Stolpersteine bei der Implementierung eines BGM umgehst",
        "Was es eigentlich bedeutet, Gesundheit im Unternehmen erfolgreich zu managen",
      ],
    },
  },
};
