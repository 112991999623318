interface SpeakerDetailsTextBlockProps {
  description: string;
  className?: string;
}

const SpeakerDetailsTextBlock = ({
  description,
  className = "",
}: SpeakerDetailsTextBlockProps) => {
  return <p className={`mb-4 ${className}`}>{description}</p>;
};

export default SpeakerDetailsTextBlock;
