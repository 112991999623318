import { ColorType } from "../../../types/ColorType";
import LogoFullCircleSemicircles from "../../svg/logos/LogoFullCircleSemicircles";
import { ISpeaker } from "../../../types/SpeakerTypes";
import SpeakerDetailsContact from "../../text/SpeakerDetailsContact";
import SpeakerCardPortrait from "./SpeakerCardPortrait";
import SpeakerCardNameTitle from "./SpeakerCardNameTitle";

interface SpeakerCardProps {
  speaker: ISpeaker;
  placement?: "left" | "right";
  logoColor: ColorType;
  href?: string;
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({
  speaker,
  placement = "left",
  logoColor,
  href,
}) => {
  const content = (
    <div
      className={`flex w-full items-center ${
        placement === "left" ? "flex-row" : "flex-row-reverse"
      }`}
    >
      <LogoFullCircleSemicircles
        className={`w-1/3 md:w-auto ${
          logoColor === ColorType.Mint
            ? "fill-mint"
            : logoColor === ColorType.Black
            ? "fill-black"
            : "fill-orange"
        } ${placement === "left" ? "mr-4" : "ml-4 rotate-180"}`}
        width={150}
      />
      <SpeakerCardPortrait speaker={speaker} href={href} />
      <div
        className={`flex flex-col w-1/3 md:w-full ${
          placement === "left"
            ? "text-left ml-4 justify-start"
            : "text-right mr-4 justify-end"
        }`}
      >
        <SpeakerCardNameTitle speaker={speaker} href={href} />
        {speaker.details && speaker.details.contact && (
          <SpeakerDetailsContact
            contact={speaker.details.contact}
            color={logoColor}
            className={`${
              placement === "left" ? "justify-start" : "justify-end"
            }`}
          />
        )}
      </div>
    </div>
  );

  return content;
};

export default SpeakerCard;
