import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  ISpeaker,
  SpeakerSessionType,
  BioType,
  LearningsType,
} from "../types/SpeakerTypes";

export const CHRISTIAN_GEBERT: ISpeaker = {
  slug: "christian-gebert",
  name: "Christian Gebert",
  title: "Geschäftsführer @ steuerberaten.de",
  imageUrl: CONFIG.imageUrl + "christian-gebert.jpg",
  color: ColorType.Mint,
  details: {
    speakerSession: {
      description: ["18 Tipps zu steuerbegünstigten Gehaltsbestandteilen"],
      sessionType: SpeakerSessionType.Workshop,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Christian Gebert absolvierte ein Studium zum Diplom-Kaufmann in Dresden und Halle (Saale). Nach seinem Studium war er als Beteiligungscontroller sowie für eine mittelständische Steuerberatungskanzlei tätig.",
        "Seit 2016 ist Christian Gebert geschäftsführender Gesellschafter der steuerberaten.de Steuerberatungsgesellschaft mbH, eine 100% digitale Steuerkanzlei.  Dabei legt er hohen Wert auf den Einsatz digitaler Medien und eine dynamische Unternehmenskultur.",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Workshop lernst du:"],
      list: [
        "Welche Möglichkeiten der finanziellen Begünstigungen es gibt, die du als Arbeitgeber deinen Arbeitnehmenden bereitstellen kannst",
        "Einen generellen Überblick über alle anzapfbaren “Töpfe” kennen",
      ],
    },
    contact: {
      linkedInUrl:
        "https://www.linkedin.com/in/christian-gebert-steuerberater/",
      instagramUrl: "https://www.instagram.com/christiangebert_steuerberater/",
      tikTokUrl: "https://www.tiktok.com/@christian_gebert",
    },
  },
};
